.rich-text-list {
  ul, ol {
    margin: 16px 0;
    padding-left: 40px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;

  }

  li {
    display: list-item;
  }

  blockquote {
    margin: 16px 40px;
  }

  b {
    font-family: 'Faktum-Semi-Bold';
  }
}

.wm-text-advance {
  h2, h3, h4, h5, h6 {
    @apply inline;
  }
}



