.wm-form-field {
  @apply border focus:outline-none focus:border-primary-orange border-grey-dark rounded-5px pl-24;
  &::placeholder {
    color: var(--gray-600);
  }
&.wm-error-form-field {
  @apply border-error;
}
}

.wm-dropdown {
  select {
    color: var(--gray-500);
  }
}